.social_block{
    margin-top: 15px;
    background-color: black !important;
    
}
.social_img{
    height: 30px;
    float: left;
}
.social_btn{
    width: 100%;
    margin-right: 150px;
    color: white !important;
}