.RegStepper{
/*background-color: blue !important;
color: blue;*/
}
.RegStepper.completed{
    background-color: rgb(91 139 196) !important;
  
    }
    .RegStepper.active{
        background-color: rgb(91 139 196) !important;
      
        }