.App {
  font-family: "Inter";
  font-weight: 300;
}
h1 {
  font-family: "Inter";
  font-weight: 700;
  font-style: italic;
  color: #5b8bc4;
}

/************START Header***************/
.navbar-light {
  background-color: #638cc7 !important;
  font-family: "Inter";
  font-weight: 300;
}
.navbar-light .nav-link {
  color: white;
}
a.nav-link.active {
  color: #183921 !important;
}
/*************END Header****************/
/************START Footer***************/
.footer {
  background-color: #638cc7 !important;
  padding: 50px;
}
.footer li {
  display: inline;
}
.footer li a {
  color: white !important;
  padding: 10px;
  text-decoration: none;
}
/*************END Footer****************/
/*************STAR Форма****************/
.block_form {
  background-color: #638cc7;
  padding: 30px;
  color: white;
  border-radius: 15px;
}

.btn_form {
  color: #5b8bc4;
  font-weight: 700;
  min-width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn_form:hover {
  color: #5b8bc4;
}
.btn_form:disabled,
.btn_form[disabled] {
  color: #5b8bc4;
}
.log_form {
  color: #bb1616;
  font-weight: 700;
  text-align: center;
}
/**************END Форма*****************/
.icon_btn {
  margin-right: 10px;
}
/**********************************START LOADER 01************************************/
.loader_01 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -24px 0 #FF3D00, 24px 0 #FFF;
  }

  66% {
    background: #FF3D00;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }

  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FF3D00;
  }
}

/**********************************END LOADER 01************************************/